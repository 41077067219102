import React, { useEffect } from "react";
import { StyledContainer, StyledChangepswdBlock } from "./style";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { encryptVal } from "../../../utils/utility";
// import { SendCustomerInvoice } from "../../../features/user/authSlice";
import { sendInvoice } from "../../../features/order/orderSlice";
const initialValues = {
    invoiceOrderId: "",
};

const sendCustomerInvoiceSchema = yup.object({
    invoiceOrderId: yup.string().min(6, "Order Id must be at least 6 characters.").required("Please enter your order id."),
});

const SendCustomerInvoice = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "UK- ETA";
    }, []);

    const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: sendCustomerInvoiceSchema,
        onSubmit: (values) => {
            dispatch(sendInvoice({ orderId: values.invoiceOrderId })).unwrap().then((res) => {
                if (res.status === 1) {
                    toast.success(`${res.message}`, {className: "toast-message"});
                } else if (res.status === 401) {
                    toast.error(`${res?.message}`, {className: "toast-message"});
                }else {
                    toast.error(`${res.message}`, {className: "toast-message"});
                }
            });
        },
    });
    return (
    <StyledContainer>
        <div className="row">
            <div className="col-md-12">
            <StyledChangepswdBlock>
                <h2>Send Invoice</h2>
                <div className="table-block table-responsive">
                    <form onSubmit={handleSubmit}>
                        <table style={{ width: "100%" }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: "30%" }}>Enter Your Order ID</td>
                                    <td style={{ width: "70%" }}>
                                    <input
                                    type="text"
                                    id="invoiceOrderId"
                                    name="invoiceOrderId"
                                    className="form-control"
                                    placeholder="Order ID"
                                    autoComplete="off"
                                    value={values.invoiceOrderId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    />
                                    <p>{errors.invoiceOrderId}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td><button type="submit" className="btn blue-btn">Send Invoice</button></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </StyledChangepswdBlock>
        </div>
    </div>
    </StyledContainer>
    );
};

export default SendCustomerInvoice;
