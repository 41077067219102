export const decryptVal = (string) => {
  try {
    string = atob(string);
    if (!isNaN(string)) {
      return string / 2;
    }
    return string;
  } catch (err) {
    return 0;
  }
};

export const encryptVal = (string) => {
  try {
    if (!isNaN(string)) {
      string = parseInt(string) + parseInt(string);
    }
    string = btoa(string);
    return string.replace(/=+$/, "");
  } catch (err) {
    return 0;
  }
};

export function titleCase(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
export async function fileExists(url) {
  let status = false;
  return await fetch(url, { method: "HEAD" })
    .then((response) => {
      status = typeof response.ok !== "undefined" ? true : false;
      return status;
    })
    .catch((error) => {
      return false;
    });
}

export const countryCode = {
  "AFGHANISTAN": "93",
  "ALBANIA": "355",
  "ALGERIA": "213",
  "AMERICAN SAMOA": "1684",
  "ANDORRA": "376",
  "ANGOLA": "244",
  "ANGUILLA": "1264",
  "ANTARCTICA": "672",
  "ANTIGUA AND BARBUDA": "1268",
  "ARGENTINA": "54",
  "ARMENIA": "374",
  "ARUBA": "297",
  "AUSTRALIA": "61",
  "AUSTRIA": "43",
  "AZERBAIJAN": "994",
  "BAHAMAS": "1242",
  "BAHRAIN": "973",
  "BANGLADESH": "880",
  "BARBADOS": "1246",
  "BELARUS": "375",
  "BELGIUM": "32",
  "BELIZE": "501",
  "BENIN": "229",
  "BERMUDA": "1441",
  "BHUTAN": "975",
  "BOLIVIA": "591",
  "BOSNIA AND HERZEGOVINA": "387",
  "BOTSWANA": "267",
  "BRAZIL": "55",
  "BRITISH INDIAN OCEAN TERRITORY": "246",
  "BRITISH VIRGIN ISLANDS": "1284",
  "BRUNEI": "673",
  "BULGARIA": "359",
  "BURKINA FASO": "226",
  "BURUNDI": "257",
  "CAMBODIA": "855",
  "CAMEROON": "237",
  "CANADA": "1",
  "CAPE VERDE": "238",
  "CAYMAN ISLANDS": "1345",
  "CENTRAL AFRICAN REPUBLIC": "236",
  "CHAD": "235",
  "CHILE": "56",
  "CHINA": "86",
  "CHRISTMAS ISLAND": "61",
  "COCOS ISLANDS": "61",
  "COLOMBIA": "57",
  "COMOROS": "269",
  "COOK ISLANDS": "682",
  "COSTA RICA": "506",
  "CROATIA": "385",
  "CUBA": "53",
  "CURACAO": "599",
  "CYPRUS": "357",
  "CZECH REPUBLIC": "420",
  "DEMOCRATIC REPUBLIC OF THE CONGO": "243",
  "DENMARK": "45",
  "DJIBOUTI": "253",
  "DOMINICA": "1767",
  "DOMINICAN REPUBLIC": "1809",
  "EAST TIMOR": "670",
  "ECUADOR": "593",
  "EGYPT": "20",
  "EL SALVADOR": "503",
  "EQUATORIAL GUINEA": "240",
  "ERITREA": "291",
  "ESTONIA": "372",
  "ESWATINI": "268",
  "ETHIOPIA": "251",
  "FALKLAND ISLANDS": "500",
  "FAROE ISLANDS": "298",
  "FIJI": "679",
  "FINLAND": "358",
  "FRANCE": "33",
  "FRENCH POLYNESIA": "689",
  "GABON": "241",
  "GAMBIA": "220",
  "GEORGIA": "995",
  "GERMANY": "49",
  "GHANA": "233",
  "GIBRALTAR": "350",
  "GREECE": "30",
  "GREENLAND": "299",
  "GRENADA": "1473",
  "GUAM": "1671",
  "GUATEMALA": "502",
  "GUERNSEY": "441481",
  "GUINEA": "224",
  "GUINEABISSAU": "245",
  "GUYANA": "592",
  "HAITI": "509",
  "HONDURAS": "504",
  "HONG KONG": "852",
  "HUNGARY": "36",
  "ICELAND": "354",
  "INDIA": "91",
  "INDONESIA": "62",
  "IRAN": "98",
  "IRAQ": "964",
  "IRELAND": "353",
  "ISLE OF MAN": "441624",
  "ISRAEL": "972",
  "ITALY": "39",
  "IVORY COAST": "225",
  "JAMAICA": "1876",
  "JAPAN": "81",
  "JERSEY": "441534",
  "JORDAN": "962",
  "KAZAKHSTAN": "7",
  "KENYA": "254",
  "KIRIBATI": "686",
  "KOSOVO": "383",
  "KUWAIT": "965",
  "KYRGYZSTAN": "996",
  "LAOS": "856",
  "LATVIA": "371",
  "LEBANON": "961",
  "LESOTHO": "266",
  "LIBERIA": "231",
  "LIBYA": "218",
  "LIECHTENSTEIN": "423",
  "LITHUANIA": "370",
  "LUXEMBOURG": "352",
  "MACAU": "853",
  "MACEDONIA": "389",
  "MADAGASCAR": "261",
  "MALAWI": "265",
  "MALAYSIA": "60",
  "MALDIVES": "960",
  "MALI": "223",
  "MALTA": "356",
  "MARSHALL ISLANDS": "692",
  "MAURITANIA": "222",
  "MAURITIUS": "230",
  "MAYOTTE": "262",
  "MEXICO": "52",
  "MICRONESIA": "691",
  "MOLDOVA": "373",
  "MONACO": "377",
  "MONGOLIA": "976",
  "MONTENEGRO": "382",
  "MONTSERRAT": "1664",
  "MOROCCO": "212",
  "MOZAMBIQUE": "258",
  "MYANMAR": "95",
  "NAMIBIA": "264",
  "NAURU": "674",
  "NEPAL": "977",
  "NETHERLANDS": "31",
  "NEW CALEDONIA": "687",
  "NEW ZEALAND": "64",
  "NICARAGUA": "505",
  "NIGER": "227",
  "NIGERIA": "234",
  "NIUE": "683",
  "NORTH KOREA": "850",
  "NORTHERN MARIANA ISLANDS": "1670",
  "NORWAY": "47",
  "OMAN": "968",
  "PAKISTAN": "92",
  "PALAU": "680",
  "PALESTINE": "970",
  "PANAMA": "507",
  "PAPUA NEW GUINEA": "675",
  "PARAGUAY": "595",
  "PERU": "51",
  "PHILIPPINES": "63",
  "POLAND": "48",
  "PORTUGAL": "351",
  "PUERTO RICO": "1787",
  "QATAR": "974",
  "ROMANIA": "40",
  "RUSSIA": "7",
  "RWANDA": "250",
  "SAUDI ARABIA": "966",
  "SENEGAL": "221",
  "SERBIA": "381",
  "SINGAPORE": "65",
  "SOUTH AFRICA": "27",
  "SOUTH KOREA": "82",
  "SPAIN": "34",
  "SRI LANKA": "94",
  "SUDAN": "249",
  "SWEDEN": "46",
  "SWITZERLAND": "41",
  "TAIWAN": "886",
  "THAILAND": "66",
  "TURKEY": "90",
  "UK": "44",
  "USA": "1",
  "UNITED ARAB EMIRATES": "971",
  "URUGUAY": "598",
  "VIETNAM": "84",
  "YEMEN": "967",
  "ZAMBIA": "260",
  "ZIMBABWE": "263"
};

export const getPhoneCode = (input) => {
  // Extract the country code portion before any parentheses
  const extractedCode = input.split('(')[0].trim();
  const formattedCode = extractedCode.toUpperCase();

  // Find a key in the mapping that matches the formatted code (case-insensitive)
  const matchedKey = Object.keys(countryCode).find(
    key => key.toUpperCase() === formattedCode
  );

  return matchedKey ? countryCode[matchedKey] : input;
};