import apiService from "./ApiService";

export default class OrderService {
  static getFolderCount = (userName) => apiService.get(`admin/get-folder-count?userName=${userName}`);
  static getOrdersList = ({ orderData }) =>
    apiService.get(
      `/admin/get-orders-list/${orderData.orderName}?page=${orderData.page}&limit=${orderData.perPage}&delay=1&userName=${orderData.userName}`
    );
  static deleteOrdersData = (deleteData) =>
    apiService.put(`/admin/change-order-status`, deleteData);
  static assignOrdersData = (assignData) =>
    apiService.put(`/admin/assign-order-status`, assignData);
  static sendRefundMail = (refundData) =>
    apiService.put("/admin/move-to-complete-refund", refundData);
  static searchOrder = (data) => apiService.post(`/admin/search-order`, data);
  static moveOrderStatus = (data) =>
    apiService.put(`/admin/move-order-status`, data);
  static getOrderDetailsByOrderId = (orderId) =>
    apiService.get(`/admin/get-orders-details/${orderId}`);
  static uploadVisaDocument = (data) =>
    apiService.upload("/admin/upload-visa-document", data);
  static resendMail = (data) => apiService.post("/admin/resend-mail", data);
  static GetDefencePackPdf = (data) =>
    apiService.post("/admin/get-defence-pack-pdf", data, {
      responseType: "blob",
    });
  static defencePack = (orderId) =>
    apiService.post("/admin/defence-pack-details", orderId);
  static getCountryOrderDetails = (data) =>
    apiService.post("/admin/get-country-order-count", data);
  static getProcessedByDetails = (data) =>
    apiService.post("/admin/get-processed-by-count", data);
  static sendInvoice = (orderId) =>
    apiService.post("/admin/send-invoice", orderId);
}
