import React, { useEffect, useState } from "react";
import { StyledContainer, StyledPageTitle, StyledOrderBlock } from "./style";
import ReactDataTable from "../../../components/ReactDataTable/ReactDataTable";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersList } from "../../../features/order/orderSlice";
import { toast } from "react-toastify";
import { encryptVal } from "../../../utils/utility";
import moment from "moment";
import { jwtDecode } from "jwt-decode";
const token = localStorage.getItem("user");

const ChargeBack = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const ChargeBackList = useSelector((state) => state?.order?.ordersList);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);


  let userType;
  let userName;
  if (token !== null) {
    const decodedToken = jwtDecode(token);
    console.log(decodedToken);
    userType = decodedToken?.user?.type;
    userName = decodedToken?.user?.name;
  }

  const handleShow = (orderId) => {
    setShow(true);
    setSelectedData([orderId]);
};

  useEffect(() => {
    dispatch(
      getOrdersList({
        orderName: "chargeBack",
        page: page,
        perPage: limit,
      })
    )
      .unwrap()
      .then((res) => {
        if (res.status === 401) {
          toast.error(`${res?.message}`, { className: "toast-message" });
          localStorage.removeItem("user");
          navigate("/");
        }
      });
    document.title = "Ind-ECD";
  }, [dispatch, navigate, page, limit]);

  const tableData = ChargeBackList;

  const columns = [
    {
      name: "Order Id",
      width: "10rem",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Email",
      width: "15rem",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Telephone",
      selector: (row) => row.telephone,
      sortable: true,
    },
    {
      name: "Date & Time",
      width: "10rem",
      selector: (row) =>
        row.date
          ? moment(new Date(row.date)).format("DD-MM-YYYY hh:mm:ss")
          : "",
      sortable: true,
    },
    {
      name: "Status",
      // width: "8rem",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) =>
        tableData?.length ? (
          <span>
            <Link
              to={`/admin/view-order/${encryptVal(row?.id)}`}
              style={{
                border: "1px solid #ce1127",
                borderRadius: "5px",
                padding: " 0 5px",
                minWidth: "80px",
                display: "inline-block",
                textAlign: "center",
                height: "40px",
                lineHeight: "40px",
                color: "#111",
                margin: "5px",
                textDecoration: "none",
              }}
              className="blue-border"
            >
              View
            </Link>{" "}
            {userType === "Manager" || userType === "Admin" ? (
                  <Link
                    to="#"
                    onClick={() =>
                      handleShow({ id: row?.id, status: row?.status })
                    }
                    style={{
                      textDecoration: "none",
                      border: "1px solid #e92a11",
                      borderRadius: "5px",
                      padding: " 0 5px",
                      minWidth: "80px",
                      display: "inline-block",
                      textAlign: "center",
                      height: "40px",
                      lineHeight: "40px",
                      color: " #e92a11",
                      margin: "5px",
                    }}
                  >
                    Delete
                  </Link>
                ) : null}
          </span>
        ) : (
          " "
        ),
    },
  ];

  const handleClick = () => {
    window.location.reload();
  };

  return (
    <StyledContainer>
      <StyledPageTitle>
        <h1>ChargeBack Orders</h1>
        <button type="button" className="btn btn-success" onClick={handleClick}>
          Refresh
        </button>
      </StyledPageTitle>
      <form>
        <StyledOrderBlock>
          <div className="table-block table-responsive">
            <ReactDataTable
              customColumns={columns}
              data={tableData}
              setPage={setPage}
              setLimit={setLimit}
              orderName="chargeBack"
            />
          </div>
        </StyledOrderBlock>
      </form>
    </StyledContainer>
  );
};

export default ChargeBack;
